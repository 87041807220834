// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/***** Color list *****/
/*****Media Queries*****/
.navbar {
  font-size: 18px;
}

.navbar-brand {
  margin: 0px;
  padding: 0px;
}

.navbar-nav {
  font-family: Lato Regular;
}

.nav-bg-color {
  background-color: var(--primary) !important;
}

.nav-link {
  color: var(--secondary) !important;
  transition: color 0.3s ease;
}

.nav-link:hover,
.nav-link.active {
  color: var(--tertiary) !important;
}

.nav-effect {
  position: relative;
  overflow-x: hidden;
}
.nav-effect:before {
  content: "";
  position: absolute;
  bottom: 3.5px;
  left: -100%;
  width: 100%;
  height: 2px;
  background-color: var(--tertiary);
  transition: left 0.3s ease-in-out;
}
.nav-effect:hover:before {
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/utils/variables.scss","webpack://./src/styles/utils/mixins.scss","webpack://./src/components/NavBar/style.scss"],"names":[],"mappings":"AAAA,uBAAA;ACAA,wBAAA;ACGA;EACI,eAAA;AAAJ;;AAGA;EACI,WAAA;EACA,YAAA;AAAJ;;AAGA;EACI,yBAAA;AAAJ;;AAGA;EACI,2CAAA;AAAJ;;AAGA;EACI,kCAAA;EACA,2BAAA;AAAJ;;AAGA;;EAEI,iCAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,kBAAA;AAAJ;AACI;EACI,WAAA;EACA,kBAAA;EACA,aAAA;EACA,WAAA;EACA,WAAA;EACA,WAAA;EACA,iCFpCG;EEqCH,iCAAA;AACR;AACI;EACI,OAAA;AACR","sourcesContent":["/***** Color list *****/\n\n$primary: var(--primary);\n$secondary: var(--secondary);\n$tertiary: var(--tertiary);","/*****Media Queries*****/\n\n@mixin Small {\n    @media (min-width: 576px) {\n        @content;\n    }\n}\n\n@mixin Medium {\n    @media (min-width: 768px) {\n        @content;\n    }\n}\n\n@mixin Large {\n    @media (min-width: 992px) {\n        @content;\n    }\n}\n\n@mixin Extra-large {\n    @media (min-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin Extra-extra-large {\n    @media (min-width: 1400px) {\n        @content;\n    }\n}","@import \"../../styles/utils/variables.scss\";\n@import \"../../styles/utils/mixins.scss\";\n\n.navbar {\n    font-size: 18px;\n}\n\n.navbar-brand{\n    margin: 0px;\n    padding: 0px;\n}\n\n.navbar-nav {\n    font-family: Lato Regular ;\n}\n\n.nav-bg-color {\n    background-color: $primary !important;\n}\n\n.nav-link {\n    color: $secondary !important;\n    transition: color 0.3s ease;\n}\n\n.nav-link:hover,\n.nav-link.active {\n    color: $tertiary !important;\n}\n  \n.nav-effect {\n    position: relative;\n    overflow-x: hidden;\n    &:before {\n        content: \"\";\n        position: absolute;\n        bottom: 3.5px;\n        left: -100%;\n        width: 100%;\n        height: 2px;\n        background-color: $tertiary;\n        transition: left 0.3s ease-in-out;\n    }\n    &:hover:before {\n        left: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
